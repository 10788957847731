import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ChatBot from "./ChatBot"; // Import the ChatBot component
import colors from "../../../Constants/colors";
import {
  DeployedBotsContainerStyles,
  DeployedBotsPaperStyles,
  DeployedBotsTableContainerStyles,
  HeaderButtonStyles,
  tableCellStyleBody,
  tableCellStyleHeader,
} from "./DeployedBotsStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  getBotbyOrg,
  getCombinationDetails,
  saveDefaultCombinations,
} from "../../../features/chatbots/chatbotSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getUserBotMemory } from "../../../features/auth/authSlice";
import { Chat, Delete, Upgrade, ViewTimeline } from "@mui/icons-material";

const DeployedBots = () => {
  const [showChat, setShowChat] = useState(false);
  const [selectedBot, setSelectedBot] = useState(null);
  const [deployedBotsList, setdeployedBotsList] = useState([]);
  const [botLoading, setBotLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [botToDelete, setBotToDelete] = useState(null);
  const [showSelectedCombinations, setShowSelectedCombinations] =
    useState(false);
  const [showBotCombination, setShowBotCombination] = useState(null);
  const [botLoadingState, setBotLoadingState] = useState({});
  const isAnyBotLoading = Object.values(botLoadingState).some(
    (loading) => loading
  );

  const { organization } = useSelector((state) => state.organization);
  const { user, isSuccess } = useSelector((state) => state.auth);
  const chatbot = useSelector((state) => state.chatbot);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const check = organization?.bot.length > 0;
    const orgId = organization?._id["$oid"];
    const get_bots = async () => {
      setBotLoading(true);
      await dispatch(getBotbyOrg(orgId));
      setBotLoading(false);
    };

    if (check && !chatbot?.chatbots?.combinations) {
      get_bots();
    }
  }, [organization]);

  useEffect(() => {
    if (chatbot?.chatbots?.combinations) {
      const filteredCombinations = chatbot?.chatbots?.combinations
        .filter((combination) => {
          const hasDefaultFlow =
            combination.default_flow &&
            Object.keys(combination.default_flow).length > 0;
          return hasDefaultFlow;
        })
        .map((combination) => {
          return {
            id: combination._id["$oid"],
            ...combination,
          };
        });
      setdeployedBotsList(filteredCombinations);
    }
  }, [chatbot?.chatbots?.combinations]);

  useEffect(() => {
    const urlSegments = window.location.href.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];
    const botId = lastSegment !== "deployedBots" ? lastSegment : null;

    if (botId) {
      const bot = deployedBotsList.find((bot) => bot.id === botId);
      if (bot) {
        setSelectedBot(bot);
        setShowChat(true);
      }
    } else {
      setShowChat(false);
    }
  }, [window.location.href, deployedBotsList, dispatch, user]);

  const handleBotClick = (bot) => {
    navigate(`/dashboard/deployedBots/${bot.id}`);
    setSelectedBot(bot);
    setShowChat(true);
  };

  const handleBackClick = () => {
    setShowChat(false);
    navigate("/dashboard/deployedBots");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleOpenDeleteDialog = (bot_id) => {
    setBotToDelete(bot_id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setBotToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    // Logic for deleting the bot deployment goes here
    setOpenDeleteDialog(false);
    handleDeleteDeployedBot();
  };

  const handleDeleteDeployedBot = async () => {
    const default_dict = {};

    const bot_id = botToDelete;
    const user_id = user?.user?._id["$oid"];

    const data = {
      bot_id,
      default_dict,
      user_id,
    };
    try {
      const result = await dispatch(saveDefaultCombinations(data));

      if (result.meta.requestStatus === "fulfilled") {
        setSnackbarMessage("Bot deleted successfully!"); // Show success message
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage("Failed to delete bot."); // Handle any non-fulfilled case
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while deleting the bot.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true); // Open the snackbar in both cases
      setBotToDelete(null);
    }
  };

  const formattedDate = (uDate) => {
    if (!uDate) return "No Date Available"; // Handle null or undefined dates
    const date = new Date(uDate.$date || uDate); // Support both objects and direct date strings
    const fDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
    return fDate;
  };

  const handleOpenSelectedCombinations = async (bot) => {
    if (!bot?.default_flow || Object.keys(bot.default_flow).length === 0)
      return;

    const combos = {};
    const messages = [];

    setSnackbarMessage("Getting Deployed Combination Details");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);

    setBotLoadingState((prev) => ({ ...prev, [bot?._id["$oid"]]: true }));
    for (const type of ["retrieval", "generation", "finetune"]) {
      const comboId = bot?.default_flow?.[type];
      if (comboId) {
        try {
          console.log("first,", {
            bot_id: bot?._id["$oid"],
            type,
            combo_id: comboId,
          });
          const res = await dispatch(
            getCombinationDetails({
              bot_id: bot?._id["$oid"],
              type,
              combo_id: comboId,
            })
          );
          if (res.type.includes("fulfilled")) {
            combos[type] = res.payload.combination_details;
            messages.push(`Details fetched successfully for ${type}`);
            if (type === "generation") {
              console.log("Adding prompt_combinations to generation");
              combos[type].prompt_combinations = bot?.prompt_combinations || [];
            }
          } else {
            messages.push(`Failed to fetch details for ${type}`);
          }
        } catch (error) {
          console.error(error);
          messages.push(`Error fetching details for ${type}`);
        } finally {
          setBotLoadingState((prev) => ({
            ...prev,
            [bot?._id["$oid"]]: false,
          }));
        }
      }
    }
    setBotLoadingState((prev) => ({ ...prev, [bot?._id["$oid"]]: false }));
    // Set combinations and show all messages in snackbar
    setShowBotCombination(combos);
    setShowSelectedCombinations(true);
    setSnackbarMessage(messages.join(". "));
    setSnackbarSeverity("success");
  };

  const handleCloseSelectedCombinations = () => {
    setShowBotCombination(null);
    setShowSelectedCombinations(false);
  };

  function mapSequence(sequence) {
    const mapping = {
      basic: "Basic",
      multi_query: "Multi Phrasing",
      hyde: "HyDE",
      query_expansion: "Expansion",
      complex_to_simple: "Deconstruction",
      iterative_deconstruction: "Iterative Deconstruction",
    };

    if (sequence) {
      // Check if the sequence contains the arrow symbol
      if (sequence.includes("→")) {
        return sequence
          .split("→")
          .map((item) => mapping[item.toLowerCase()] || item)
          .join(" → ");
      }

      // Return the original sequence if no arrow symbol is found
      return mapping[sequence.toLowerCase()];
    } else {
      return "N/A";
    }
  }

  function mapDocumentParser(parser) {
    const mapping = {
      llama_parse: "Llama Parse",
      recursive_character_text_splitter: "Basic",
      unstructuredio: "Unstructured.io",
    };

    return mapping[parser] || "N/A";
  }

  const formatValue = (value) => {
    if (value === 0) return value; // Return 0 as is

    const exponent = Math.floor(Math.log10(Math.abs(value)));
    const mantissa = (value / Math.pow(10, exponent)).toFixed(2); // Mantissa with 2 decimal places

    // If the exponent is between -2 and 2 (inclusive), return the rounded value with 2 decimal places
    if (exponent >= -2 && exponent <= 2) {
      return Number(value).toFixed(2);
    }

    // Otherwise, return the mantissa and exponent in scientific notation
    return (
      <>
        {mantissa} &times; 10<sup>{exponent}</sup>
      </>
    );
  };
  return (
    <Container sx={DeployedBotsContainerStyles}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* Confirm Delete */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            backgroundColor: colors.extremeBlack,
            color: colors.extremeWhite,
            boxShadow: `0px 4px 20px ${colors.secondaryBlue}`,
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.6rem" }}>
          Confirm Deployed Bot Deletion
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderTop: `1px solid ${colors.extremeWhite}`,
          }}
        >
          <DialogContentText sx={{ my: "16px", color: colors.extremeWhite }}>
            Do you want to delete the previous deployment of the bot?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{ ...HeaderButtonStyles, bgcolor: colors.tertiaryBlue }}
          >
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            sx={HeaderButtonStyles}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* View Combinations */}
      <Dialog
        open={showSelectedCombinations}
        onClose={handleCloseSelectedCombinations}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle
          sx={{ bgcolor: colors.extremeBlue, color: colors.extremeWhite }}
        >
          Selected Combinations
        </DialogTitle>
        <DialogContent
          sx={{ bgcolor: colors.extremeBlue, color: colors.extremeWhite }}
        >
          {showBotCombination?.retrieval && (
            <TableContainer sx={DeployedBotsTableContainerStyles}>
              <Box
                sx={{
                  display: "flex",
                  padding: "20px",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: colors.extremeWhite,
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Retrieval Combination
                </Typography>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Chunk Size
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Metric Type
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Retrieval Method
                    </TableCell>

                    <TableCell sx={tableCellStyleHeader} align="center">
                      Post Retrieval
                    </TableCell>
                    <TableCell
                      sx={tableCellStyleHeader}
                      align="center"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Top K
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Embedding Model
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Document Parser
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Query Transformations
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        ...tableCellStyleHeader,
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Accuracy %
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Cost ($)
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyleHeader,
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Latency (s)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.retrieval?.chunksize}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.retrieval?.metric}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.retrieval?.retrieval_method ===
                      "complex-to-simple-query"
                        ? "deconstruction"
                        : showBotCombination?.retrieval?.retrieval_method}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.retrieval?.rerank_method}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.retrieval?.top_k}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.retrieval?.embedding_model}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {mapDocumentParser(showBotCombination?.retrieval?.parser)}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {mapSequence(
                        showBotCombination?.retrieval?.query_preprocess_method
                      )}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.retrieval?.average_rating}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyleBody,
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {showBotCombination?.completed === true &&
                        showBotCombination?.retrieval?.cost}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.retrieval?.completed === true &&
                        showBotCombination?.retrieval?.latency}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {showBotCombination?.generation && (
            <TableContainer sx={DeployedBotsTableContainerStyles}>
              <Box
                sx={{
                  display: "flex",
                  padding: " 20px",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: colors.extremeWhite,
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Foundational LLM Combination
                </Typography>
              </Box>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      LLM
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyleHeader,
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Prompt Type
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Prompt
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Temperature
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyleHeader,
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Output Tokens Limit
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        ...tableCellStyleHeader,
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Accuracy (%)
                    </TableCell>
                    {/* <TableCell  sx={tableCellStyleHeader} align="center">Status</TableCell> */}
                    <TableCell sx={tableCellStyleHeader} align="center">
                      Cost ($)
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyleHeader,
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Latency (s)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.generation.llm}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.generation.prompts}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      <Tooltip
                        title={
                          showBotCombination?.generation?.prompt_combinations[
                            showBotCombination?.generation.prompts
                          ] || ""
                        }
                        arrow
                      >
                        <span>
                          {showBotCombination?.generation?.prompt_combinations[
                            showBotCombination?.generation.prompts
                          ]
                            ? showBotCombination?.generation
                                ?.prompt_combinations[
                                showBotCombination?.generation.prompts
                              ].length > 40
                              ? showBotCombination?.generation?.prompt_combinations[
                                  showBotCombination?.generation.prompts
                                ].substring(0, 40) + "..."
                              : showBotCombination?.generation
                                  ?.prompt_combinations[
                                  showBotCombination?.generation.prompts
                                ]
                            : ""}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.generation.temperature}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.generation.outputTokenLimit === "0"
                        ? "N/A"
                        : showBotCombination?.generation.outputTokenLimit}
                    </TableCell>

                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.generation?.average_rating}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyleBody,
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {formatValue(
                        showBotCombination?.generation?.inference_cost
                      )}
                    </TableCell>
                    <TableCell sx={tableCellStyleBody} align="center">
                      {showBotCombination?.generation?.completed === true &&
                        showBotCombination?.generation?.latency}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>
      {botLoading ? (
        <Box
          sx={{
            ...DeployedBotsPaperStyles,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Loading bots...
          </Typography>
        </Box>
      ) : deployedBotsList?.length > 0 ? (
        !showChat ? (
          <Box sx={DeployedBotsPaperStyles}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Select a Bot to start Conversation
            </Typography>
            <TableContainer sx={DeployedBotsTableContainerStyles}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        ...tableCellStyleHeader,
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                      }}
                      align="left"
                    >
                      Bot Name
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="left">
                      Description
                    </TableCell>
                    <TableCell sx={tableCellStyleHeader} align="left">
                      Last Updated
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyleHeader,
                        position: "sticky",
                        right: 0,
                        backgroundColor: colors.primaryBlue,
                        zIndex: 1,
                      }}
                      align="left"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deployedBotsList.map((bot) => (
                    <TableRow
                      key={bot.id}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: colors.primaryBlue,
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          ...tableCellStyleBody,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                        }}
                        align="left"
                      >
                        {bot.name}
                      </TableCell>
                      <TableCell sx={tableCellStyleBody} align="left">
                        {bot.problem_statement}
                      </TableCell>
                      <TableCell sx={tableCellStyleBody} align="left">
                        {formattedDate(bot.updated_at)}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyleBody,
                          position: "sticky",
                          right: 0,
                          zIndex: 1,
                        }}
                        align="left"
                      >
                        <Tooltip title="View Selected Combinations">
                          <IconButton
                            onClick={() => handleOpenSelectedCombinations(bot)}
                            sx={{
                              ":hover": {
                                color: colors.primaryBlue,
                              },
                              color: colors.extremeWhite,
                              mr: 2,
                            }}
                            disabled={isAnyBotLoading}
                          >
                            {botLoadingState[bot.id] ? (
                              <CircularProgress size={20} />
                            ) : (
                              <ViewTimeline fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Chat with Deployed Bot">
                          <IconButton
                            onClick={() => handleBotClick(bot)}
                            sx={{
                              ":hover": {
                                color: colors.primaryBlue,
                              },
                              color: colors.extremeWhite,
                              mr: 2,
                            }}
                            disabled={isAnyBotLoading}
                          >
                            <Chat fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {(user?.user?.privilege === "owner" ||
                          user?.user?.privilege === "admin") && (
                          <Tooltip title="Delete Deployed Bot">
                            <IconButton
                              onClick={() => handleOpenDeleteDialog(bot?.id)}
                              sx={{
                                ":hover": {
                                  color: "red",
                                },
                                color: colors.extremeWhite,
                              }}
                              disabled={isAnyBotLoading}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <ChatBot
            selectedBot={selectedBot}
            handleBackClick={handleBackClick}
          />
        )
      ) : (
        <Box
          sx={{
            ...DeployedBotsPaperStyles,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            No Bots Deployed
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default DeployedBots;
